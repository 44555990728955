.boxStyle {
  max-width: 500px;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperStyle {
  max-width: 500px;
  max-height: 400px;
  display: flex; 
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 16px;
} 

.logoStyle {
  max-width: 400px;
  object-fit: scale-down;
}

.headingStyle {
  text-align: center;
}